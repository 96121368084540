import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Components/App';
import reportWebVitals from './reportWebVitals';
import Wrapper from './Components/Wrapper'
import { BrowserRouter } from 'react-router-dom';
import Topbar from './Components/Topbar'
import TagManager from 'react-gtm-module';
import ResponsiveAppBar from './Components/ResponsiveAppBar';

//Do not initialize GTM for localhost
if(window.location.host && !window.location.host.startsWith("localhost")){
  const tagManagerArgs = {
    gtmId: 'GTM-N2MF3R3K'
  }
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Wrapper children={<App/>}></Wrapper>
    </BrowserRouter>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
