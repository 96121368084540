import React from 'react'

export default function Wrapper({ children }) {
  return (
    <div>
        <div id="wrapper1">
            {children}
        </div>
    </div>
    
  )
}
