import { Card, CardActionArea, CardContent, CardMedia,  Skeleton,  Typography} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

import { useEffect } from 'react';
import { Link} from 'react-router-dom';
import { categories } from '../Data' 
import { ProductMap } from '../SortedData';

//Static initializations
const productMap = ProductMap; 
const imagePrefix = window.location.origin+"/images/products/"

export default function Gallery(props) {

    useEffect(() => {
        document.title  = "FabUtsav";
      });
    
  return (
    <>

    <Grid2 container spacing={2}  justifyContent = 'center'>
        {categories.map((item) => (
            <Grid2 spacing={{ xs: 3, md: 3 }} columns={{ xs: 12, sm: 6, md: 4 }} key={item.Image}>
                <Card sx={{ width:300 }}>
                    <CardActionArea >
                        <Link to={"/products/"+item.CategoryCode} style={{textDecoration:'none', font:'inherit', color:'inherit'}}>
                            {item.Image?(
                                <div>
                                    <div style={{height:"350", maxHeight:"350"}}>
                                    <CardMedia
                                        component="img"
                                        style={{maxHeight:"100%", maxWidth:"100%"}}
                                        image={`${imagePrefix}${item.Image}?w=161&fit=cover&auto=format`}
                                        alt={item.Name}
                                    />
                                    </div>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" color={'primary'}>
                                            {item.Name}
                                        </Typography>
                                        <Typography gutterBottom component="div">
                                            {productMap[item.CategoryCode] && productMap[item.CategoryCode].length}&nbsp;Designs
                                        </Typography>
                                        
                                    </CardContent>
                                </div>
                            ):(
                                <div>
                                    <Skeleton variant='rounded' width={300} height={350} />
                                </div>
                            )
                            }

                        </Link>
                    </CardActionArea>
                </Card>
            </Grid2>
        ))}
    </Grid2>
    </>
  )
}
