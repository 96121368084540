import Gallery from './Gallery';
import ContactUs from './ContactUs'
import Products from './Products';
import ItemGroup from './ItemGroup';
import ItemCard from './ItemCard';
import {
  Routes,
  Route
} from "react-router-dom";
import ProductOptions from './ProductOptions';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Home from './Home';
import React from 'react';
import { useEffect } from 'react';

export const ScreenContext = React.createContext();

function App() {

  useEffect(() => {
    // Initialize dataLayer if it's not already initialized
    window.dataLayer = window.dataLayer || [];

    // Push referrer to dataLayer
    window.dataLayer.push({
      'event': 'page_load',
      'referrer': document.referrer
    });

  }, []);

  return (
    <>
      <div className="App">

        <Grid2 container justifyContent='center'>
          <Grid2>
            <img alt='FabUtsav' src='/images/nav-logo.png' width={100} />
          </Grid2>
        </Grid2>
        <Routes>
          <Route exact path="/" element={<Home children={<Gallery />} />} />
          <Route path="/category/:CategoryCode" element={<Home children={<Products />} />} />
          <Route exact path="/item/:ItemCode" element={<Home children={<ItemGroup />} />} />
          <Route path="/item/:ParentCode/:VariantCode" element={<Home children={<ItemCard />} />} />

          <Route exact path="/gallery" element={<Home children={<Gallery />} />} />
          <Route path="/contactus" element={<Home children={<ContactUs />} />} />
          <Route exact path="/products/:categoryCode" element={<Home children={<Products />} />} />
          <Route path="/products/:categoryCode/:itemCode" element={<Home children={<ProductOptions />} />} />
        </Routes>

      </div>
    </>
  );
}

export default App;
