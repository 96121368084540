import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React from 'react'

export default function ContactUs() {
  
  document.title  = "FabUtsav - Contact Us";
  return (
    <>
      <br/>
      <div className='m-2'>
        <Typography variant='h5' className="mt-4">About Us</Typography>
        <div className='row col-xs-12 col-md-8'>
          <p>We are a family owned and operated Indian handicrafts business. Our products are made using traditional techniques, skills and hand-based processes. The products are craft based, contemporary, and affordable. 
            We offer a range of products from home decor to festive decors, customary gifts and hampers. We can also do customised designs locally so no long waiting time and high quality product.
          </p>
        </div>
        <div className="mt-4">
          Follow us on &nbsp;
          <a href="https://www.facebook.com/byfabutsav" style={{textDecoration:'none', verticalAlign:'middle'}}  rel="noreferrer" target="_blank">
                <img src="https://img.icons8.com/doodle/48/000000/facebook-circled.png" alt='facebook' className="m-0" style={{maxHeight:"32px"}}/>
               </a>
        </div>
        {/* https://img.icons8.com/doodle/48/000000/facebook-circled.png */}
        <div className="mt-4"> 
            For order enquiries
           
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem component="a" href='tel:+61404239011' target={"_blank"}>
              <ListItemAvatar>
                <Avatar sx={{bgcolor:"rgba(210,180,140,0)"}} variant="rounded">
                <img alt="sms" src="https://img.icons8.com/doodle/48/000000/touchscreen-smartphone.png" style={{maxHeight:"32px"}}/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="(+61)&nbsp;404&nbsp;239&nbsp;011" secondary=" " />
            </ListItem>
            <ListItem component="a" href='https://wa.me/+61404239011' target={"_blank"}>
              <ListItemAvatar>
                <Avatar sx={{bgcolor:"rgba(210,180,140,0)"}} variant="rounded">
                <img alt='whatsapp' src="https://img.icons8.com/doodle/48/000000/whatsapp.png" style={{maxHeight:"32px"}}/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="WhatsApp" secondary="" />
            </ListItem>
            <ListItem component="a" href='http://m.me/byfabutsav' target={"_blank"}>
              <ListItemAvatar>
                <Avatar sx={{bgcolor:"rgba(210,180,140,0)"}} variant="rounded">
                <img alt="messenger" src="https://img.icons8.com/cotton/64/000000/facebook-messenger--v4.png" style={{maxHeight:"32px"}}/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Messenger" secondary=" " />
            </ListItem>
          </List>
        </div>
      </div>
    </>
  )
}
