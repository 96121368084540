
import { Box, Tooltip } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { ProductMap } from '../SortedData';

export default function ItemGroup() {
  const { ItemCode } = useParams();
  const selectedItem = ProductMap.find(p => p.ItemCode === ItemCode);
  const variants = ProductMap.filter(p => p.ParentCode === ItemCode);

  return (
    <div>
      {/* Display main item details */}
      {/* ... existing item details ... */}

      <Box display="flex" flexDirection="column" gap={2} mt={2}>
        {variants.map(variant => (
          <Tooltip title={variant.Name} key={variant.ItemCode}>
            <Box
              component={Link}
              to={`/item/${selectedItem.ItemCode}/${variant.ItemCode}`}
              display="flex"
              alignItems="center"
              gap={2}
            >
              <Box
                width={40}
                height={20}
                bgcolor={variant.Colour || 'grey'}
                border={variant.Available === 'yes' ? '2px solid black' : '2px dashed grey'}
                borderRadius="4px"
              />
              <Box>{variant.Name}</Box>
            </Box>
          </Tooltip>
        ))}
      </Box>

    </div>
  );
}
