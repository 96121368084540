import { Breadcrumbs, Card, CardActionArea, CardContent, CardMedia, Skeleton, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { categoriesMap } from '../Data'; 
    import { ProductMap } from '../SortedData';
import { SubItems } from '../SortedData';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

export default function Products(props) { 
    const {categoryCode} = useParams(); 
    const category= categoryCode ? categoriesMap[categoryCode]: {} 
    const products = ProductMap[categoryCode]
    const imagePrefix = window.location.origin+"/images/products/"
    var images = {};

    function setImages(item){
        images[item.ItemCode] = {loading : true, path : imagePrefix + item.Image};
    }

  return (
    <> 
        <Breadcrumbs style={{mt:0}}>
            <Link underline="hover" color="inherit" to={"/"}>
                Gallery
            </Link>
            <Link underline="hover" color="inherit" to={"#"}>
                {category.Name}
            </Link>
        </Breadcrumbs>
        <hr style={{width:'100%'}} />
        <Grid2 container spacing={2}  justifyContent = 'center'>
            {products.map((item) => ( 
                <Grid2 spacing={{ xs: 3, md: 3 }} columns={{ xs: 12, sm: 6, md: 4 }} key={item.Image} >
                    <Card sx={{ width:300 }} onLoad={setImages(item)}>
                        <CardActionArea >
                            <Link to={"/products/"+categoryCode+"/"+item.ItemCode} style={{textDecoration:'none', font:'inherit', color:'inherit'}}>
                                {images[item.ItemCode].path?(
                                    <div>
                                        <div style={{height:"350", maxHeight:"350"}}>
                                        <CardMedia
                                            component="img"
                                            style={{maxHeight:"100%", maxWidth:"100%"}}
                                            image={`${imagePrefix}${item.Image}?w=161&fit=cover&auto=format`}
                                            alt={item.Name}
                                            loading='lazy'
                                        />
                                        </div>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div" color={'primary'}>
                                                {item.Name}
                                            </Typography>
                                            <Typography variant="h5" color="text.secondary">
                                                {/* {item.Price ? '$' + item.Price : ''} */}
                                                <br />
                                            </Typography>
                                            <Typography gutterBottom component="div">
                                                {SubItems[item.ItemCode]&& SubItems[item.ItemCode].length>1?
                                                  SubItems[item.ItemCode].length + " Designs" 
                                                  : ""} 
                                            </Typography> 
                                        </CardContent>
                                    </div>
                                ):(
                                    <div>
                                        <Skeleton variant='rounded' width={300} height={350} />
                                    </div>
                                )
                                }

                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid2>
            ))}
        </Grid2> 
    </>
  )
}
