export const productInventory =
    [
        {
            "ItemCode": "RG-04",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG-04",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG-04.jpeg",
            "Price": 65,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG-06",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG-06",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG-06.jpeg",
            "Price": 65,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG-25",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG-25",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG-25.jpeg",
            "Price": 65,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG21-2",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG21-2",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG21-2.jpeg",
            "Price": 70,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG21-4",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG21-4",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG21-4.jpeg",
            "Price": 60,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG21-5",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG21-5",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG21-5.jpeg",
            "Price": 70,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG21-9",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG21-9",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG21-9.jpeg",
            "Price": 60,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG21-14",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG21-14",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG21-14.jpeg",
            "Price": 65,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG21-67",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG21-67",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG21-67.jpeg",
            "Price": 65,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-07",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-07",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-07.jpeg",
            "Price": 60,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-28",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-28",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-28.jpeg",
            "Price": 35,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-36",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-36",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-36.jpeg",
            "Price": 35,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-39",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-39",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-39.jpeg",
            "Price": 65,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-46",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-46",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-46.jpeg",
            "Price": 65,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-47",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-47",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-47.jpeg",
            "Price": 65,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-50",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-50",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-50.jpeg",
            "Price": 65,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-52",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-52",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-52.jpeg",
            "Price": 60,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-65",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-65",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-65.jpeg",
            "Price": 20,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-66",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-66",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-66.jpeg",
            "Price": 65,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-87",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-87",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-87.jpeg",
            "Price": 70,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-95",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-95",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-95.jpeg",
            "Price": 60,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-114",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-114",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-114.jpeg",
            "Price": 65,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-116",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-116",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-116.jpeg",
            "Price": 150,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-JK4",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-JK4",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-JK4.jpeg",
            "Price": 40,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-JK10",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-JK10",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-JK10.jpeg",
            "Price": 40,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-JK14",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-JK14",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-JK14.jpeg",
            "Price": 60,
            "ParentCode": ""
        },
        {
            "ItemCode": "RG22-JK16",
            "CategoryCode": "RG",
            "Category": "Rangoli",
            "Name": "RG22-JK16",
            "Description": "Rangoli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "RG22-JK16.jpeg",
            "Price": 55,
            "ParentCode": ""
        },
        {
            "ItemCode": "Urli1",
            "CategoryCode": "Urli",
            "Category": "Urli",
            "Name": "Urli1",
            "Description": "Urli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "Urli1.jpg",
            "Price": 200,
            "ParentCode": ""
        },
        {
            "ItemCode": "Urli2",
            "CategoryCode": "Urli",
            "Category": "Urli",
            "Name": "Urli2",
            "Description": "Urli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "Urli2.jpg",
            "Price": 150,
            "ParentCode": ""
        },
        {
            "ItemCode": "Urli3",
            "CategoryCode": "Urli",
            "Category": "Urli",
            "Name": "Urli3",
            "Description": "Urli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "Urli3.jpg",
            "Price": 220,
            "ParentCode": ""
        },
        {
            "ItemCode": "Urli4",
            "CategoryCode": "Urli",
            "Category": "Urli",
            "Name": "Urli4",
            "Description": "Urli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "Urli4.jpg",
            "Price": 220,
            "ParentCode": ""
        },
        {
            "ItemCode": "Urli5",
            "CategoryCode": "Urli",
            "Category": "Urli",
            "Name": "Urli5",
            "Description": "Urli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "Urli5.jpg",
            "Price": 150,
            "ParentCode": ""
        },
        {
            "ItemCode": "Urli6",
            "CategoryCode": "Urli",
            "Category": "Urli",
            "Name": "Urli6",
            "Description": "Urli",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "Urli6.jpg",
            "Price": 120,
            "ParentCode": ""
        },
        {
            "ItemCode": "H23-001",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-001",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "H23-001.jpg",
            "Price": 40,
            "ParentCode": "H23-001"
        },
        {
            "ItemCode": "H23-001b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-001b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "H23-001b.jpg",
            "Price": 40,
            "ParentCode": "H23-001"
        },
        {
            "ItemCode": "H23-002",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-002",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "H23-002.jpg",
            "Price": 35,
            "ParentCode": "H23-002"
        },
        {
            "ItemCode": "H23-003",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-003",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-003.jpg",
            "Price": 35,
            "ParentCode": "H23-003"
        },
        {
            "ItemCode": "H23-004",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-004",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-004.jpg",
            "Price": 40,
            "ParentCode": "H23-004"
        },
        {
            "ItemCode": "H23-005",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-005",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-005.jpg",
            "Price": 20,
            "ParentCode": "H23-005"
        },
        {
            "ItemCode": "H23-006a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-006a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-006a.jpg",
            "Price": 20,
            "ParentCode": "H23-006a"
        },
        {
            "ItemCode": "H23-006b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-006b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-006b.jpg",
            "Price": 20,
            "ParentCode": "H23-006a"
        },
        {
            "ItemCode": "H23-007a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-007a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-007a.jpg",
            "Price": 18,
            "ParentCode": "H23-007a"
        },
        {
            "ItemCode": "H23-007b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-007b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-007b.jpg",
            "Price": 18,
            "ParentCode": "H23-007a"
        },
        {
            "ItemCode": "H23-007c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-007c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-007c.jpg",
            "Price": 18,
            "ParentCode": "H23-007a"
        },
        {
            "ItemCode": "H23-008",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-008",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-008.jpg",
            "Price": 45,
            "ParentCode": "H23-008"
        },
        {
            "ItemCode": "H23-009a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-009a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-009a.jpg",
            "Price": 45,
            "ParentCode": "H23-009a"
        },
        {
            "ItemCode": "H23-010a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-010a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-010a.jpg",
            "Price": 25,
            "ParentCode": "H23-010a"
        },
        {
            "ItemCode": "H23-010b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-010b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-010b.jpg",
            "Price": 25,
            "ParentCode": "H23-010a"
        },
        {
            "ItemCode": "H23-010c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-010c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-010c.jpg",
            "Price": 25,
            "ParentCode": "H23-010a"
        },
        {
            "ItemCode": "H23-011a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-011a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-011a.jpg",
            "Price": 18,
            "ParentCode": "H23-011a"
        },
        {
            "ItemCode": "H23-011b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-011b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-011b.jpg",
            "Price": 18,
            "ParentCode": "H23-011a"
        },
        {
            "ItemCode": "H23-011c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-011c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-011c.jpg",
            "Price": 18,
            "ParentCode": "H23-011a"
        },
        {
            "ItemCode": "H23-012",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-012",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-012.jpg",
            "Price": 45,
            "ParentCode": "H23-012"
        },
        {
            "ItemCode": "H23-013",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-013",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-013.jpg",
            "Price": 40,
            "ParentCode": "H23-013"
        },
        {
            "ItemCode": "H23-014",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-014",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-014.jpg",
            "Price": 40,
            "ParentCode": "H23-014"
        },
        {
            "ItemCode": "H23-015a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-015a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-015a.jpg",
            "Price": 45,
            "ParentCode": "H23-015a"
        },
        {
            "ItemCode": "H23-015b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-015b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-015b.jpg",
            "Price": 45,
            "ParentCode": "H23-015a"
        },
        {
            "ItemCode": "H23-016a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-016a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-016a.jpg",
            "Price": 45,
            "ParentCode": "H23-016a"
        },
        {
            "ItemCode": "H23-016b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-016b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-016b.jpg",
            "Price": 45,
            "ParentCode": "H23-016a"
        },
        {
            "ItemCode": "H23-016c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-016c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-016c.jpg",
            "Price": 45,
            "ParentCode": "H23-016a"
        },
        {
            "ItemCode": "H23-017a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-017a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-017a.jpg",
            "Price": 22,
            "ParentCode": "H23-017a"
        },
        {
            "ItemCode": "H23-017b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-017b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-017b.jpg",
            "Price": 22,
            "ParentCode": "H23-017a"
        },
        {
            "ItemCode": "H23-017c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-017c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-017c.jpg",
            "Price": 22,
            "ParentCode": "H23-017a"
        },
        {
            "ItemCode": "H23-017d",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-017d",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-017d.jpg",
            "Price": 22,
            "ParentCode": "H23-017a"
        },
        {
            "ItemCode": "H23-017e",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-017e",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-017e.jpg",
            "Price": 22,
            "ParentCode": "H23-017a"
        },
        {
            "ItemCode": "H23-020a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-020a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-020a.jpg",
            "Price": 25,
            "ParentCode": "H23-020a"
        },
        {
            "ItemCode": "H23-020b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-020b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-020b.jpg",
            "Price": 25,
            "ParentCode": "H23-020a"
        },
        {
            "ItemCode": "H23-020c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-020c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-020c.jpg",
            "Price": 25,
            "ParentCode": "H23-020a"
        },
        {
            "ItemCode": "H23-020d",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-020d",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-020d.jpg",
            "Price": 25,
            "ParentCode": "H23-020a"
        },
        {
            "ItemCode": "H23-021a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-021a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-021a.jpg",
            "Price": 35,
            "ParentCode": "H23-021b"
        },
        {
            "ItemCode": "H23-021b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-021b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-021b.jpg",
            "Price": 35,
            "ParentCode": "H23-021b"
        },
        {
            "ItemCode": "H23-021c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-021c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-021c.jpg",
            "Price": 35,
            "ParentCode": "H23-021b"
        },
        {
            "ItemCode": "H23-021d",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-021d",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-021d.jpg",
            "Price": 35,
            "ParentCode": "H23-021b"
        },
        {
            "ItemCode": "H23-021e",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-021e",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-021e.jpg",
            "Price": 35,
            "ParentCode": "H23-021b"
        },
        {
            "ItemCode": "H23-021f",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-021f",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-021f.jpg",
            "Price": 35,
            "ParentCode": "H23-021b"
        },
        {
            "ItemCode": "H23-021g",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-021g",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-021g.jpg",
            "Price": 35,
            "ParentCode": "H23-021b"
        },
        {
            "ItemCode": "H23-022a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-022a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-022a.jpg",
            "Price": 35,
            "ParentCode": "H23-022a"
        },
        {
            "ItemCode": "H23-022b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-022b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-022b.jpg",
            "Price": 35,
            "ParentCode": "H23-022a"
        },
        {
            "ItemCode": "H23-022c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-022c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-022c.jpg",
            "Price": 35,
            "ParentCode": "H23-022a"
        },
        {
            "ItemCode": "H23-022d",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-022d",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-022d.jpg",
            "Price": 35,
            "ParentCode": "H23-022a"
        },
        {
            "ItemCode": "H23-022e",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-022e",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-022e.jpg",
            "Price": 35,
            "ParentCode": "H23-022a"
        },
        {
            "ItemCode": "H23-022f",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-022f",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-022f.jpg",
            "Price": 35,
            "ParentCode": "H23-022a"
        },
        {
            "ItemCode": "H23-23a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-23a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-23a.jpg",
            "Price": 35,
            "ParentCode": "H23-23a"
        },
        {
            "ItemCode": "H23-23b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-23b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-23b.jpg",
            "Price": 35,
            "ParentCode": "H23-23a"
        },
        {
            "ItemCode": "H23-23c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-23c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-23c.jpg",
            "Price": 35,
            "ParentCode": "H23-23a"
        },
        {
            "ItemCode": "H23-23d",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-23d",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-23d.jpg",
            "Price": 35,
            "ParentCode": "H23-23a"
        },
        {
            "ItemCode": "H23-23e",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-23e",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-23e.jpg",
            "Price": 35,
            "ParentCode": "H23-23a"
        },
        {
            "ItemCode": "H23-24a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-24a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-24a.jpg",
            "Price": 35,
            "ParentCode": "H23-24a"
        },
        {
            "ItemCode": "H23-24b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-24b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-24b.jpg",
            "Price": 35,
            "ParentCode": "H23-24a"
        },
        {
            "ItemCode": "H23-24c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-24c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-24c.jpg",
            "Price": 35,
            "ParentCode": "H23-24a"
        },
        {
            "ItemCode": "H23-24d",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-24d",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-24d.jpg",
            "Price": 35,
            "ParentCode": "H23-24a"
        },
        {
            "ItemCode": "H23-24e",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-24e",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-24e.jpg",
            "Price": 35,
            "ParentCode": "H23-24a"
        },
        {
            "ItemCode": "H23-25",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-25",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-25.jpg",
            "Price": 45,
            "ParentCode": "H23-25"
        },
        {
            "ItemCode": "H23-26",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-26",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-26.jpg",
            "Price": 25,
            "ParentCode": "H23-26"
        },
        {
            "ItemCode": "H23-26b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-26b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-26b.jpg",
            "Price": 22,
            "ParentCode": "H23-27"
        },
        {
            "ItemCode": "H23-27a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-27a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-27a.jpg",
            "Price": 22,
            "ParentCode": "H23-27a"
        },
        {
            "ItemCode": "H23-27b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-27b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-27b.jpg",
            "Price": 22,
            "ParentCode": "H23-27a"
        },
        {
            "ItemCode": "H23-27c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-27c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-27c.jpg",
            "Price": 22,
            "ParentCode": "H23-27a"
        },
        {
            "ItemCode": "H23-27d",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-27d",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-27d.jpg",
            "Price": 22,
            "ParentCode": "H23-27a"
        },
        {
            "ItemCode": "H23-28a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-28a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-28a.jpg",
            "Price": 22,
            "ParentCode": "H23-28a"
        },
        {
            "ItemCode": "H23-28b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-28b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-28b.jpg",
            "Price": 22,
            "ParentCode": "H23-28a"
        },
        {
            "ItemCode": "H23-28c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-28c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-28c.jpg",
            "Price": 22,
            "ParentCode": "H23-28a"
        },
        {
            "ItemCode": "H23-29a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-29a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-29a.jpg",
            "Price": 25,
            "ParentCode": "H23-29a"
        },
        {
            "ItemCode": "H23-29b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-29b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-29b.jpg",
            "Price": 25,
            "ParentCode": "H23-29a"
        },
        {
            "ItemCode": "H23-30",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-30",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-30.jpg",
            "Price": 25,
            "ParentCode": "H23-30"
        },
        {
            "ItemCode": "H23-31",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-31",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-31.jpg",
            "Price": 18,
            "ParentCode": "H23-31"
        },
        {
            "ItemCode": "H23-32",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-32",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-32.jpg",
            "Price": 45,
            "ParentCode": "H23-32"
        },
        {
            "ItemCode": "H23-33a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-33a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-33a.jpg",
            "Price": 25,
            "ParentCode": "H23-33a"
        },
        {
            "ItemCode": "H23-33b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-33b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-33b.jpg",
            "Price": 25,
            "ParentCode": "H23-33a"
        },
        {
            "ItemCode": "H23-34",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-34",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-34.jpg",
            "Price": 25,
            "ParentCode": "H23-34"
        },
        {
            "ItemCode": "H23-35",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-35",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-35.jpg",
            "Price": 30,
            "ParentCode": "H23-35"
        },
        {
            "ItemCode": "H23-36",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-36",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-36.jpg",
            "Price": 15,
            "ParentCode": "H23-36"
        },
        {
            "ItemCode": "H23-37",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-37",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-37.jpg",
            "Price": 35,
            "ParentCode": "H23-37"
        },
        {
            "ItemCode": "H23-38",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-38",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-38.jpg",
            "Price": 35,
            "ParentCode": "H23-38"
        },
        {
            "ItemCode": "H23-39",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-39",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-39.jpg",
            "Price": 35,
            "ParentCode": "H23-39"
        },
        {
            "ItemCode": "H23-40a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-40a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-40a.jpg",
            "Price": 22,
            "ParentCode": "H23-40a"
        },
        {
            "ItemCode": "H23-40b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-40b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-40b.jpg",
            "Price": 22,
            "ParentCode": "H23-40a"
        },
        {
            "ItemCode": "H23-40c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-40c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-40c.jpg",
            "Price": 22,
            "ParentCode": "H23-40a"
        },
        {
            "ItemCode": "H23-40d",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-40d",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-40d.jpg",
            "Price": 22,
            "ParentCode": "H23-40a"
        },
        {
            "ItemCode": "H23-40e",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-40e",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-40e.jpg",
            "Price": 22,
            "ParentCode": "H23-40a"
        },
        {
            "ItemCode": "H23-40f",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-40f",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-40f.jpg",
            "Price": 22,
            "ParentCode": "H23-40a"
        },
        {
            "ItemCode": "H23-41",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-41",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-41.jpg",
            "Price": 20,
            "ParentCode": "H23-41"
        },
        {
            "ItemCode": "H23-41b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-41b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-41b.jpg",
            "Price": 20,
            "ParentCode": "H23-41"
        },
        {
            "ItemCode": "H23-41c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-41c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-41c.jpg",
            "Price": 20,
            "ParentCode": "H23-41"
        },
        {
            "ItemCode": "H23-42",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-42",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-42.jpg",
            "Price": 30,
            "ParentCode": "H23-42"
        },
        {
            "ItemCode": "H23-43",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-43",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-43.jpg",
            "Price": 45,
            "ParentCode": "H23-43"
        },
        {
            "ItemCode": "H23-43a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-43a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-43a.jpg",
            "Price": 45,
            "ParentCode": "H23-43"
        },
        {
            "ItemCode": "H23-43b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-43b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-43b.jpg",
            "Price": 45,
            "ParentCode": "H23-43"
        },
        {
            "ItemCode": "H23-44",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-44",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-44.jpg",
            "Price": 25,
            "ParentCode": "H23-44"
        },
        {
            "ItemCode": "H23-45",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-45",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-45.jpg",
            "Price": 40,
            "ParentCode": "H23-45"
        },
        {
            "ItemCode": "H23-46",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-46",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-46.jpg",
            "Price": 45,
            "ParentCode": "H23-46"
        },
        {
            "ItemCode": "H23-47",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-47",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-47.jpg",
            "Price": 45,
            "ParentCode": "H23-47"
        },
        {
            "ItemCode": "H23-48",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-48",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-48.jpg",
            "Price": 45,
            "ParentCode": "H23-48"
        },
        {
            "ItemCode": "H23-49",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-49",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-49.jpg",
            "Price": 35,
            "ParentCode": "H23-49"
        },
        {
            "ItemCode": "H23-50",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-50",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-50.jpg",
            "Price": 18,
            "ParentCode": "H23-50"
        },
        {
            "ItemCode": "H23-51",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-51",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-51.jpg",
            "Price": 18,
            "ParentCode": "H23-51"
        },
        {
            "ItemCode": "H23-52",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-52",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-52.jpg",
            "Price": 22,
            "ParentCode": "H23-52"
        },
        {
            "ItemCode": "H23-53a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-53a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-53a.jpg",
            "Price": 30,
            "ParentCode": "H23-53a"
        },
        {
            "ItemCode": "H23-53b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-53b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-53b.jpg",
            "Price": 30,
            "ParentCode": "H23-53a"
        },
        {
            "ItemCode": "H23-54",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-54",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-54.jpg",
            "Price": 30,
            "ParentCode": "H23-54"
        },
        {
            "ItemCode": "H23-55",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-55",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-55.jpg",
            "Price": 30,
            "ParentCode": "H23-55"
        },
        {
            "ItemCode": "H23-56",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-56",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-56.jpg",
            "Price": 35,
            "ParentCode": "H23-56"
        },
        {
            "ItemCode": "H23-57",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-57",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-57.jpg",
            "Price": 45,
            "ParentCode": "H23-57"
        },
        {
            "ItemCode": "H23-57a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-57a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-57a.jpg",
            "Price": 45,
            "ParentCode": "H23-57"
        },
        {
            "ItemCode": "H23-58a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-58a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-58a.jpg",
            "Price": 45,
            "ParentCode": "H23-58a"
        },
        {
            "ItemCode": "H23-58b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-58b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-58b.jpg",
            "Price": 45,
            "ParentCode": "H23-58a"
        },
        {
            "ItemCode": "H23-58c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-58c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-58c.jpg",
            "Price": 45,
            "ParentCode": "H23-58a"
        },
        {
            "ItemCode": "H23-59",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-59",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-59.jpg",
            "Price": 45,
            "ParentCode": "H23-59"
        },
        {
            "ItemCode": "H23-59a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-59a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-59a.jpg",
            "Price": 45,
            "ParentCode": "H23-59"
        },
        {
            "ItemCode": "H23-59b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-59b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-59b.jpg",
            "Price": 45,
            "ParentCode": "H23-59"
        },
        {
            "ItemCode": "H23-59c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-59c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-59c.jpg",
            "Price": 45,
            "ParentCode": "H23-59"
        },
        {
            "ItemCode": "H23-60",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-60",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-60.jpg",
            "Price": 30,
            "ParentCode": "H23-60"
        },
        {
            "ItemCode": "H23-61",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-61",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-61.jpg",
            "Price": 45,
            "ParentCode": "H23-61"
        },
        {
            "ItemCode": "H23-62",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-62",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-62.jpg",
            "Price": 30,
            "ParentCode": "H23-62"
        },
        {
            "ItemCode": "H23-63a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-63a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-63a.jpg",
            "Price": 30,
            "ParentCode": "H23-63a"
        },
        {
            "ItemCode": "H23-63b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-63b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-63b.jpg",
            "Price": 30,
            "ParentCode": "H23-63a"
        },
        {
            "ItemCode": "H23-63c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-63c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-63c.jpg",
            "Price": 30,
            "ParentCode": "H23-63a"
        },
        {
            "ItemCode": "H23-63d",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-63d",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-63d.jpg",
            "Price": 30,
            "ParentCode": "H23-63a"
        },
        {
            "ItemCode": "H23-63e",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-63e",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-63e.jpg",
            "Price": 30,
            "ParentCode": "H23-63a"
        },
        {
            "ItemCode": "H23-64",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-64",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-64.jpg",
            "Price": 35,
            "ParentCode": "H23-64"
        },
        {
            "ItemCode": "H23-65",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-65",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-65.jpg",
            "Price": 20,
            "ParentCode": "H23-65"
        },
        {
            "ItemCode": "H23-65a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-65a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-65a.jpg",
            "Price": 20,
            "ParentCode": "H23-65"
        },
        {
            "ItemCode": "H23-65b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-65b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-65b.jpg",
            "Price": 20,
            "ParentCode": "H23-65"
        },
        {
            "ItemCode": "H23-65c",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-65c",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-65c.jpg",
            "Price": 20,
            "ParentCode": "H23-65"
        },
        {
            "ItemCode": "H23-66",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-66",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-66.jpg",
            "Price": 15,
            "ParentCode": "H23-66"
        },
        {
            "ItemCode": "H23-67",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-67",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-67.jpg",
            "Price": 25,
            "ParentCode": "H23-67"
        },
        {
            "ItemCode": "H23-68",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-68",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-68.jpg",
            "Price": 30,
            "ParentCode": "H23-68"
        },
        {
            "ItemCode": "H23-69",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-69",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-69.jpg",
            "Price": 45,
            "ParentCode": "H23-69"
        },
        {
            "ItemCode": "H23-70",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-70",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-70.jpg",
            "Price": 20,
            "ParentCode": "H23-70"
        },
        {
            "ItemCode": "H23-71",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-71",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-71.jpg",
            "Price": 45,
            "ParentCode": "H23-71"
        },
        {
            "ItemCode": "H23-72",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-72",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-72.jpg",
            "Price": 30,
            "ParentCode": "H23-72"
        },
        {
            "ItemCode": "H23-72a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-72a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-72a.jpg",
            "Price": 40,
            "ParentCode": "H23-72"
        },
        {
            "ItemCode": "H23-73",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-73",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-73.jpg",
            "Price": 40,
            "ParentCode": "H23-73"
        },
        {
            "ItemCode": "H23-74",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-74",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-74.jpg",
            "Price": 30,
            "ParentCode": "H23-74"
        },
        {
            "ItemCode": "H23-75",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-75",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-75.jpg",
            "Price": 30,
            "ParentCode": "H23-75"
        },
        {
            "ItemCode": "H23-76",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-76",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-76.jpg",
            "Price": 35,
            "ParentCode": "H23-76"
        },
        {
            "ItemCode": "H23-77",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-77",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-77.jpg",
            "Price": 25,
            "ParentCode": "H23-77"
        },
        {
            "ItemCode": "H23-78",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-78",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-78.jpg",
            "Price": 45,
            "ParentCode": "H23-78"
        },
        {
            "ItemCode": "H23-78a",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-78a",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-78a.jpg",
            "Price": 45,
            "ParentCode": "H23-78"
        },
        {
            "ItemCode": "H23-78b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-78b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-78b.jpg",
            "Price": 45,
            "ParentCode": "H23-78"
        },
        {
            "ItemCode": "H23-79",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-79",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-79.jpg",
            "Price": 45,
            "ParentCode": "H23-79"
        },
        {
            "ItemCode": "H23-80",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-80",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-80.jpg",
            "Price": 30,
            "ParentCode": "H23-80"
        },
        {
            "ItemCode": "H23-81",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-81",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-81.jpg",
            "Price": 25,
            "ParentCode": "H23-81"
        },
        {
            "ItemCode": "H23-82b",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-82b",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-82b.jpg",
            "Price": 25,
            "ParentCode": "H23-82b"
        },
        {
            "ItemCode": "H23-83",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-83",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-83.jpg",
            "Price": 35,
            "ParentCode": "H23-83"
        },
        {
            "ItemCode": "H23-84",
            "CategoryCode": "HG",
            "Category": "Hanging",
            "Name": "H23-84",
            "Description": "Hanging",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "H23-84.jpg",
            "Price": 30,
            "ParentCode": "H23-84"
        },
        {
            "ItemCode": "T23-01",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-01",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "T23-01.jpg",
            "Price": 55,
            "ParentCode": "T23-01"
        },
        {
            "ItemCode": "T23-02",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-02",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "T23-02.jpg",
            "Price": 55,
            "ParentCode": "T23-02"
        },
        {
            "ItemCode": "T23-03",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-03",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "yes",
            "Image": "T23-03.jpg",
            "Price": 50,
            "ParentCode": "T23-03"
        },
        {
            "ItemCode": "T23-04",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-04",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-04.jpg",
            "Price": 50,
            "ParentCode": "T23-04"
        },
        {
            "ItemCode": "T23-05",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-05",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-05.jpg",
            "Price": 50,
            "ParentCode": "T23-05"
        },
        {
            "ItemCode": "T23-06",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-06",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-06.jpg",
            "Price": 50,
            "ParentCode": "T23-06"
        },
        {
            "ItemCode": "T23-07",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-07",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-07.jpg",
            "Price": 50,
            "ParentCode": "T23-07"
        },
        {
            "ItemCode": "T23-08",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-08",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-08.jpg",
            "Price": 50,
            "ParentCode": "T23-08"
        },
        {
            "ItemCode": "T23-09",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-09",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-09.jpg",
            "Price": 55,
            "ParentCode": "T23-09"
        },
        {
            "ItemCode": "T23-10",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-10",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-10.jpg",
            "Price": 55,
            "ParentCode": "T23-10"
        },
        {
            "ItemCode": "T23-11",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-11",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-11.jpg",
            "Price": 55,
            "ParentCode": "T23-11"
        },
        {
            "ItemCode": "T23-12",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-12",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-12.jpg",
            "Price": 50,
            "ParentCode": "T23-12"
        },
        {
            "ItemCode": "T23-13",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-13",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-13.jpg",
            "Price": 50,
            "ParentCode": "T23-13"
        },
        {
            "ItemCode": "T23-14",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-14",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-14.jpg",
            "Price": 50,
            "ParentCode": "T23-14"
        },
        {
            "ItemCode": "T23-15",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-15",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-15.jpg",
            "Price": 25,
            "ParentCode": "T23-15"
        },
        {
            "ItemCode": "T23-16",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-16",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-16.jpg",
            "Price": 30,
            "ParentCode": "T23-16"
        },
        {
            "ItemCode": "T23-17",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-17",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-17.jpg",
            "Price": 50,
            "ParentCode": "T23-17"
        },
        {
            "ItemCode": "T23-18",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-18",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-18.jpg",
            "Price": 50,
            "ParentCode": "T23-18"
        },
        {
            "ItemCode": "T23-19",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-19",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-19.jpg",
            "Price": 55,
            "ParentCode": "T23-19"
        },
        {
            "ItemCode": "T23-20",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-20",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-20.jpg",
            "Price": 55,
            "ParentCode": "T23-20"
        },
        {
            "ItemCode": "T23-21",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-21",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-21.jpg",
            "Price": 50,
            "ParentCode": "T23-21"
        },
        {
            "ItemCode": "T23-22",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-22",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-22.jpg",
            "Price": 50,
            "ParentCode": "T23-22"
        },
        {
            "ItemCode": "T23-23",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-23",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-23.jpg",
            "Price": 50,
            "ParentCode": "T23-23"
        },
        {
            "ItemCode": "T23-24",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-24",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-24.jpg",
            "Price": 55,
            "ParentCode": "T23-24"
        },
        {
            "ItemCode": "T23-25",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-25",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-25.jpg",
            "Price": 55,
            "ParentCode": "T23-25"
        },
        {
            "ItemCode": "T23-26",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-26",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-26.jpg",
            "Price": 55,
            "ParentCode": "T23-26"
        },
        {
            "ItemCode": "T23-27",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-27",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-27.jpg",
            "Price": 55,
            "ParentCode": "T23-27"
        },
        {
            "ItemCode": "T23-28",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-28",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-28.jpg",
            "Price": 45,
            "ParentCode": "T23-28"
        },
        {
            "ItemCode": "T23-29a",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-29a",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-29a.jpg",
            "Price": 50,
            "ParentCode": "T23-29a"
        },
        {
            "ItemCode": "T23-29b",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-29b",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-29b.jpg",
            "Price": 50,
            "ParentCode": "T23-29a"
        },
        {
            "ItemCode": "T23-30",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-30",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-30.jpg",
            "Price": 50,
            "ParentCode": "T23-30"
        },
        {
            "ItemCode": "T23-31",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-31",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-31.jpg",
            "Price": 50,
            "ParentCode": "T23-31"
        },
        {
            "ItemCode": "T23-32",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-32",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-32.jpg",
            "Price": 50,
            "ParentCode": "T23-32"
        },
        {
            "ItemCode": "T23-33",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-33",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-33.jpg",
            "Price": 50,
            "ParentCode": "T23-33"
        },
        {
            "ItemCode": "T23-34",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-34",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-34.jpg",
            "Price": 50,
            "ParentCode": "T23-34"
        },
        {
            "ItemCode": "T23-35",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-35",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-35.jpg",
            "Price": 50,
            "ParentCode": "T23-35"
        },
        {
            "ItemCode": "T23-36",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-36",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-36.jpg",
            "Price": 40,
            "ParentCode": "T23-36"
        },
        {
            "ItemCode": "T23-37",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-37",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-37.jpg",
            "Price": 40,
            "ParentCode": "T23-37"
        },
        {
            "ItemCode": "T23-38",
            "CategoryCode": "TH",
            "Category": "Toran",
            "Name": "T23-38",
            "Description": "Toran",
            "Size": "",
            "Colour": "",
            "Tags": "",
            "Available": "",
            "Image": "T23-38.jpg",
            "Price": 40,
            "ParentCode": "T23-38"
        }
    ];

    export const categories = 
   [
    {"CategoryCode": "RG","Name": "Rangoli", "Description": "","Image":"RG-25.jpeg"},
    {"CategoryCode": "Urli","Name": "Urli", "Description": "","Image":"Urli2.jpg"},
    {"CategoryCode": "TH","Name": "Torans", "Description": "","Image":"T23-11.jpg"},
    {"CategoryCode": "HG","Name": "Hangings", "Description": "","Image":"H23-24b.jpg"}
    ]

export const categoriesMap = {
    "RG": { "CategoryCode": "RG", "Name": "Rangoli", "Description": "", "Image": "RG-25.jpeg" },
    "Urli": { "CategoryCode": "Urli", "Name": "Urli", "Description": "", "Image": "Urli2.jpg" },
    "HG": { "CategoryCode": "HG", "Name": "Hangings", "Description": "", "Image": "H23-24b.jpg" },
    "TH": { "CategoryCode": "TH", "Name": "Torans", "Description": "", "Image": "T23-11.jpg" }
}
