import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel'; 
import ContactUs from './ContactUs';

export default function Home({children}) {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', m:2 }}>
          <TabList onChange={handleChange} textColor="secondary"
              indicatorColor="secondary">
            <Tab label="Gallery" value="1" />
            <Tab label="Contact Us" value="2"/>
          </TabList>
        </Box>
        <TabPanel value="1">
            {children}
        </TabPanel>
        <TabPanel value="2">
            <ContactUs/>
        </TabPanel> 
      </TabContext>
    </Box>
  );
}
