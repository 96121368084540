import { Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import React, { useEffect } from 'react';

export default function ItemCard(props) {
  const image = "/images/products/" + props.item.Image;
  useEffect(() => {
    document.title = "FabUtsav - " + props.item.Category + " - " + props.item.Name;
  });

  return (
    <>

      <Card sx={{ maxWidth: 345 }}>
        <CardMedia component="img" image={`${image}?w=161&fit=cover&auto=format`} 
        style={{maxHeight:"100%", maxWidth:"100%"}} />
        <CardContent>
          <Typography gutterBottom variant='h5' component="div" color={'primary'}>
            {props.item.Name}
          </Typography>
          <Typography variant="h5" color="text.secondary">
            {/* {props.item.Price ? '$' + props.item.Price : ''} */}
          </Typography>
        </CardContent>
        <CardActions>
        </CardActions>
      </Card>

    </>
  )
}