import { Breadcrumbs } from '@mui/material';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { categoriesMap } from '../Data';
import { SubItems } from '../SortedData';
import { productInventory } from '../Data';
import ItemCard from './ItemCard';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2


export default function ProductsOptions(props) {
  const { categoryCode } = useParams();
  const { itemCode } = useParams();
  const category = categoryCode ? categoriesMap[categoryCode] : {}
  const item = productInventory.filter((val) => {
    if (val.ItemCode === itemCode) {
      return val;
    }
  })[0];
  var subItems = SubItems[itemCode];

  useEffect(() => {
    document.title = "FabUtsav - " + category.Name + " - " + itemCode;
  });

  return (
    <div>
      <Breadcrumbs style={{ marginTop: '-1', padding: '1 0 1 0' }}>
        <Link underline="hover" to={"/gallery"} style={{ textDecoration: 'none', font: 'inherit', color: 'inherit' }}>
          Gallery
        </Link>
        <Link underline="hover" to={"/products/" + item.CategoryCode} style={{ textDecoration: 'none', font: 'inherit', color: 'inherit' }}>
          {category.Name}
        </Link>
        <Link underline="hover" to={"#"} style={{ textDecoration: 'none', font: 'inherit', color: 'inherit' }}>
          {item.Name}
        </Link>
      </Breadcrumbs>
      <hr style={{ width: '100%', padding: '0 1 0 0' }} />
      <Grid2 spacing={{ xs: 3, md: 3 }} columns={12} sx={{ justifyItems: 'center', width: '100%' }}>
        {(subItems && subItems.length > 0) ?
          subItems.map(variant => (
            <Grid2 spacing={{ xs: 3, md: 3 }} columns={{ xs: 12, sm: 6, md: 4 }} key={variant.ItemCode}>
              <ItemCard item={variant} key={variant.ItemCode} />
            </Grid2>
          ))
          :
          <ItemCard item={item} key={item.ItemCode} />
        }

      </Grid2>
    </div>
  )
}
